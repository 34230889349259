<template>
  <ui-component-modal
    :modalTitle="
      $t('Components.Carts.EditCartMeetingspaces.Title_AddReservationSpace')
    "
    modalSize="large"
    :onClickSave="addReservationSpaces"
    :isSaving="isSaving"
    :isSavingError="isSavingError"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :savingErrorMessage="
      $t('Components.Reservation.ModalEditSpace.Message_Error', {
        responseMessage: responseMessage,
      })
    "
    :disableSaveButton="spaces.length === 0"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <CheckAvailability
        :channelId="channelId"
        :locationId="locationId"
        :meetingtypeId="meetingtypeId"
        :showDateInput="false"
        :startDate="startDate"
        :endDate="endDate"
        :startMinutes="startMinutes"
        :endMinutes="endMinutes"
        :voucherId="voucherId"
        :selectedSpaces="spaces"
        @callbackShowSelectedSpaces="(val) => (showSelectedSpaces = val)"
      />

      <table
        v-if="meetingtypeId === 1 && spaces.length > 0 && showSelectedSpaces"
        class="table is-fullwidth is-striped is-small is-hoverable"
      >
        <thead>
          <tr>
            <th>
              {{ $t('Components.Reservation.ModalAddSpace.Table_Seats') }}
            </th>
            <th>
              {{
                $t('Components.Reservation.ModalAddSpace.Table_SpaceSettings')
              }}
            </th>
            <th width="125">
              {{ $t('Components.Reservation.ModalAddSpace.Table_Time') }}
            </th>
            <!-- <th width="175">
              {{ $t('Components.Reservation.ModalAddSpace.Table_Setting') }}
            </th> -->
            <th width="125" class="has-text-right">
              {{ $t('Components.Reservation.ModalAddSpace.Table_Price') }}
            </th>
            <th width="125" class="has-text-right">
              {{ $t('Components.Reservation.ModalAddSpace.Table_Total') }}
            </th>
            <th width="150" class="has-text-right">
              {{
                $t(
                  'Components.Reservation.ModalAddSpace.Table_UpdateTotalSeats'
                )
              }}
            </th>
            <th width="25" class="has-text-right">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(space, index) in spaces"
            :key="index"
            class="has-text-vertical-middle"
          >
            <td>{{ space.Seats }}x</td>
            <td>
              {{ space.SpaceName }}<br />
              <span class="has-text-grey">{{
                space.SettingId | getSettingName
              }}</span>
            </td>
            <td>
              {{ space.StartMinutes | minutesToTime }} -
              {{ space.EndMinutes | minutesToTime }}
            </td>
            <!--<td>
               {{ space.SettingId | getSettingName }}
            </td> -->
            <td class="has-text-right">
              <div class="field has-addons">
                <p class="control">
                  <a class="button is-static" v-html="space.CurrencySymbol"></a>
                </p>
                <p class="control">
                  <InputNumeric
                    v-model.number="space.PricePerSeat"
                    @change="updateCrcValue(space)"
                    @keyup="delay(updateCrcValue(space), 200)"
                    :class="{
                      'is-danger': String(space.PricePerSeat).length === 0,
                    }"
                  />
                </p>
              </div>
            </td>
            <td class="has-text-right">
              {{
                calculateTotalPrice(space)
                  | toCurrency(
                    locationState.Settings.LanguageCulture,
                    space.CurrencyIso
                  )
              }}
            </td>
            <td class="has-text-right">
              <div class="tabs is-right is-toggle is-small">
                <ul>
                  <li
                    :class="{ 'is-active': space.UpdateTotalSeats === false }"
                  >
                    <a @click="space.UpdateTotalSeats = false">
                      <span v-text="$t('General.No')"></span>
                    </a>
                  </li>
                  <li :class="{ 'is-active': space.UpdateTotalSeats === true }">
                    <a @click="space.UpdateTotalSeats = true">
                      <span v-text="$t('General.Yes')"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <p class="control">
                <a @click="removeSelectedSpace(index)">
                  <span class="icon has-text-danger">
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </span>
                </a>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'
import { mapState, mapMutations } from 'vuex'
import crcProvider from '@/providers/crc'
import reservationProvider from '@/providers/reservation'
import InputNumeric from '../UI/Form/InputNumeric'

const CheckAvailability = () =>
  import('@/components/Availability/CheckAvailability')

export default {
  components: {
    CheckAvailability,
    InputNumeric,
  },

  props: {
    autoSearch: {
      default: false,
      type: Boolean,
    },
    canChangeDate: {
      default: false,
      type: Boolean,
    },
    channelId: {
      default: 0,
      type: Number,
    },
    countryId: {
      default: 0,
      type: Number,
    },
    endDate: {
      default: function() {
        return new Date()
      },
      type: Date,
    },
    endMinutes: {
      default: 0,
      type: Number,
    },
    locationId: {
      default: 0,
      type: Number,
    },
    meetingtypeId: {
      default: 1,
      type: Number,
    },
    seats: {
      default: 1,
      type: Number,
    },
    voucherId: {
      default: 0,
      type: Number,
    },
    settingId: {
      default: 0,
      type: Number,
    },
    showDateInput: {
      default: false,
      type: Boolean,
    },
    startDate: {
      default: function() {
        return new Date()
      },
      type: Date,
    },
    startMinutes: {
      default: 0,
      type: Number,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingError: false,
      showSelectedSpaces: false,
      responseMessage: '',
      spaces: [],
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
    ...mapState('reservationStore', ['reservation']),
  },

  watch: {},

  created() {
    let self = this

    EventBus.$on('spaceSelected', (space) => {
      space.ReservationId = self.reservation.Id
      space.UpdateTotalSeats = false
      self.addSpace(space)
      self.showSelectedSpaces = true
    })
  },

  beforeDestroy() {
    EventBus.$off('spaceSelected')
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    addSpace(space) {
      this.spaces.push(space)
    },

    delay(callback, ms) {
      let self = this
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        callback.apply(self)
        clearTimeout(self.timer)
      }, ms || 0)
    },

    updateCrcValue(space) {
      crcProvider.methods
        .checkCrc(space.SpaceId, space.PricePerSeat)
        .then((response) => {
          if (response.status === 200) {
            space.Crc = response.data
            space.PriceTotal = this.calculateTotalPrice(space)
          }
        })
    },

    calculateTotalPrice(space) {
      let total = 0.0

      if (space) {
        if (space.CalculationType === 'PerDayPartOnly') {
          total = Number(space.PricePerSeat)
        } else {
          total =
            Math.round(Number(space.Seats) * Number(space.PricePerSeat) * 100) /
            100
        }
      }

      return total
    },

    addReservationSpaces() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        reservationProvider.methods
          .addReservationSpaces(self.reservation.Id, self.spaces)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              // Update reservation store
              self.setReservation(response.data)

              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: this.$t(
                  'Components.Reservation.ModalAddSpace.Toast_SpacesAdded'
                ),
              })

              this.onClickCancel()
            }
          })
          .catch((error) => {
            self.isSavingError = true
            self.responseMessage = error.response.data.Value
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    removeSelectedSpace(index) {
      this.spaces.splice(index, 1)
    },
  },
}
</script>
